<template>
    <div id="viewerContainer">
    </div>
</template>
<style>
    #viewerContainer {
        background-color: #e5e5e5;
        height: 109vh;
    }
</style>
<script lang="js">
import Vue from "vue";
import { env } from '@/environment/environment';
import "@grapecity/ar-viewer/dist/jsViewer.min.js";
import "@grapecity/ar-viewer/dist/jsViewer.min.css";

export default Vue.extend({
  name: "Viewer",
  props: ['reportName', 'dataUrl', 'reportParams', 'printOnly'],        
  data: function() {
    return {
        viewer: {},
    };
  },
  methods: {
    printReport: function() {
      this.viewer.print();
    },
    exportToCsv: function() {
      // this.viewer.exportsSettings = {'tabular-data': {
      //   outputType: "plain",
      //   // default value for other TabularData export settings
      // }};
      this.viewer.export('csv', null, true, {PrintOnOpen: 'true'});
    },
    openReport: function () {
      console.log("openReport()");
      if(this.printOnly) {
        this.viewer.sidebar.toggle(false);
      }

      let params = [
          { name: 'API_URL', values: [`${env.VUE_APP_API_URL}${this.dataUrl}`]},
        ];

      console.log("Report URL: "+`${env.VUE_APP_API_URL}${this.dataUrl}`);

      if(this.reportParams){
        params = params.concat(this.reportParams);
      }    
      this.viewer.openReport(
        this.reportName,
        params
      );
    }    
  },
  mounted() {
          this.viewer = new GrapeCity.ActiveReports.JSViewer.create({                
            element: '#viewerContainer',
            reportService: {
              url: `${env.VUE_APP_API_URL}/api/reporting`,
              onRequest: (init) => {init.credentials = 'omit';}
            },
            documentLoaded: (x) => {console.log('The document was successfully loaded!'); console.log(x)},
            reportLoaded: (reportInfo) => {console.log('The report ' + reportInfo.name + ' was successfully loaded!'); console.log(reportInfo)}
          });
          
          this.openReport();
  },
  watch: {
    reportName() {
      this.viewer = new GrapeCity.ActiveReports.JSViewer.create({                
            element: '#viewerContainer',
            reportService: {
              url: `${env.VUE_APP_API_URL}/api/reporting`,
              onRequest: (init) => {init.credentials = 'omit';}
            },
            documentLoaded: (x) => {console.log('The document was successfully Re-loaded!'); console.log(x)},
            reportLoaded: (reportInfo) => {console.log('The report ' + reportInfo.name + ' was successfully loaded!'); console.log(reportInfo)}
          });
      this.openReport();
    },            
    dataUrl() {
      this.openReport();
    },            
    reportParams() {
      this.openReport();
    }
  },  
});
</script>