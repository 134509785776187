
import Vue from "vue";
import ReportViewer from '@/components/Admin/Reports/ReportViewer.vue';
import { UserInfo } from "@/types/User";

export default Vue.extend({
  name: "MembershipReport",
  components: {
    ReportViewer
  },
  data: function() {
    return {
      showLoader: false,
      errorMessage: "",
      mailATClassFilters: [
        {value: 'true', label: '1st'},
        {value: 'false', label: '2nd'},
      ],
      selectedATClasses: [
        'true', 'false'
      ]
    };
  },
  computed: {
    userInfo(): UserInfo {
      return this.$store.state.userInfo;
    },
    reportViewer(): any {
      return this.$refs.reportViewer;
    }
  },
  methods: {
    async csvExport() {      
      this.reportViewer.exportToCsv('csv', null, false, {PrintOnOpen: 'true'})
    },
  },
});
