import { api } from "../api";

import { ShowAffiliateInfo } from '@/types/admin/Show';
import { AffiliateInfo } from '@/types/admin/Affiliate';
import { PaginationOptions, PaginatedResponse } from "@/types/Pagination";

export const getAffiliatesList = async (
    options: PaginationOptions,
    params: object,
): Promise<PaginatedResponse<object>> => {
    const { page, itemsPerPage, sortBy, sortDesc } = options;

    const sort = !!sortBy.length ? sortBy[0] : 'requestId';
    const asce = !!sortDesc.length ? !sortDesc[0] : true;

    const { data } = await api.get<PaginatedResponse<object>>(`/Admin/affiliates?pageSize=${itemsPerPage}&page=${page}&ascending=${asce}&sort=${sort}`, { params });

    return data;
};

export const getAffiliateById = async (affiliateId: string): Promise<AffiliateInfo> => {
    const { data } = await api.get<AffiliateInfo>(`/Admin/affiliates/details/${affiliateId}`);

    return data;
};

export const getShowAffiliateById = async (affiliateId: string): Promise<ShowAffiliateInfo> => {
    const { data } = await api.get<ShowAffiliateInfo>(`/Admin/affiliates/details/${affiliateId}`);

    return data;
};

export const editAffiliateById = async (request: any): Promise<any> => {
    const { data } = await api.post<object>(`/Admin/affiliates/details/edit`, request);
    return data;
};

export const validateAffiliateEdit = async (request: any): Promise<any> => {
    const { data } = await api.post<object>(`/Admin/affiliates/details/validate`, request);
    return data;
};

export const affiliatesGeneratePointSchedules = async (startDate: any, endDate: any): Promise<any> => {
    var request = { startDate: startDate, endDate: endDate };
    const { data } = await api.post<object>(`/Admin/affiliates/pointschedules/generate`, request);
    return data;
};

export const getAffiliateShowCoordinators = async (affiliateId: string): Promise<any> => {
    const { data } = await api.get<object[]>(`/Admin/affiliates/showcoords/${affiliateId}`);

    return data;
};

export const getAffiliateHomeStates = async (): Promise<object> => {
    const { data } = await api.get<object>(`/Affiliate/states`);
    return data;
};

export default {
    getAffiliatesList,
    getAffiliateById,
    editAffiliateById,
    validateAffiliateEdit,
    affiliatesGeneratePointSchedules,
    getShowAffiliateById,
    getAffiliateShowCoordinators,
    getAffiliateHomeStates,
};