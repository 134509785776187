import { RouteConfig } from "vue-router";
import { MetaNames } from "@/constants";

// request lists & searches
import ChangeKennelOwnerCard from "../views/Admin/Request/ChangeKennelOwnerCard.vue";
import CreateAffiliateCard from "../views/Admin/Request/CreateAffiliateCard.vue";
import QTDogRequestCard from "../views/Admin/Request/QTDogRequestCard.vue";
import KennelRegistrationCard from "../views/Admin/Request/KennelRegistrationCard.vue";
import LeaseRequestCard from "../views/Admin/Request/LeaseRequestCard.vue";
import LitterRequestCard from "../views/Admin/Request/LitterRequestCard.vue";
import RenewAffiliateCard from "../views/Admin/Request/RenewAffiliateCard.vue";

import ChangeKennelOwnerList from "../views/Admin/Requests/ChangeKennelOwnerList.vue";
import CreateAffiliateList from "../views/Admin/Requests/CreateAffiliateList.vue";
import QTDogRegistrationList from "../views/Admin/Requests/QTDogRegistrationList.vue";
import KennelRegistrationList from "../views/Admin/Requests/KennelRegistrationList.vue";
import LeaseRequestList from "../views/Admin/Requests/LeaseRequestList.vue";
import LitterRequestList from "../views/Admin/Requests/LitterRequestList.vue";
import RenewAffiliateList from "../views/Admin/Requests/RenewAffiliateList.vue";

// searches
import AdminAffiliateList from "../views/Admin/Searches/AffiliateList.vue";
import KennelList from "../views/Admin/Searches/KennelList.vue";
import LitterList from "../views/Admin/Searches/LitterList.vue";
import LeaseList from "../views/Admin/Searches/LeaseList.vue";
import PuppyReleaseList from "../views/Admin/Searches/PuppyReleaseList.vue"
import ShowList from "../views/Admin/Searches/ShowList.vue";

// detail screens
import AffiliateDetails from "../views/Admin/Details/AffiliateDetails.vue";
import KennelDetails from "../views/Admin/Details/KennelDetails.vue";
import LitterDetails from "../views/Admin/Details/LitterDetails.vue";
import PersonDetails from "../views/Admin/Details/PersonDetails.vue";
import DogDetails from "../views/Admin/Details/DogDetails.vue";
import LeaseDetails from "../views/Admin/Details/LeaseDetails.vue";
import ShowDetails from "../views/Admin/Details/ShowDetails.vue";

// titling
import TitlingReview from "../views/Admin/Titling/Review.vue";
import TitlingProcess from "../views/Admin/Titling/Process.vue";
import CertPrinting from "../views/Admin/Titling/CertPrinting.vue";

// other
import Payments from "../views/Admin/Payments.vue";
import ReportsPage from "../views/Admin/ReportsPage.vue";
import MembersOnlyEdit from "../views/MembersOnlyEdit.vue";
import MembershipReport from "../views/MembershipReport.vue";
import RegisterLease from "../views/Admin/RegisterLease.vue";
import IdMaintenance from "../views/Admin/IdMaintenance.vue";
import LookupsMaintenance from "../views/Admin/LookupsMaintenance.vue";

// used as components
import DogList from "../components/Admin/Searches/DogList.vue";
import PeopleList from "../components/Admin/Searches/PeopleList.vue";
import CategoryManagement from "../components/Admin/CategoryManagement.vue";

const decodeURIComponentIfNotEmpty = (uri: any) => { 
  if (uri){
    return decodeURIComponent(uri);
  }
  return uri; 
}; 

const convertToNullableNumber = (id: string) => { 
  if (id){
    return Number(id);
  }
  return id; 
}; 


const routes: Array<RouteConfig> = [     
    {
        path: '/admin/lookups',
        name: 'LookupsMaintenance',
        component: LookupsMaintenance,
        props: true,
        meta: {
            layout: 'left-sidebar', name: MetaNames.AdminLookupMaintenance }

    },
    {
        path: '/admin/idconfig',
        name: 'IdMaintenance',
        component: IdMaintenance,
        props: true,
        meta: {
            layout: 'left-sidebar', name: MetaNames.AdminIdConfig }

    },
    {
        path: '/admin/payments',
        name: 'AdminPayments',
        component: Payments,
        props: true,
        meta: {
            layout: 'left-sidebar', name: MetaNames.AdminPayments }
    },
    {
        path: '/admin/reports',
        name: 'reportspage',
        component: ReportsPage,
        meta: { layout: 'left-sidebar', name: MetaNames.AdminReports }
    },
    {
        path: '/admin/titling/review/:program',
        name: 'titlingreviewprogram',
        component: TitlingReview,
        props: route => ({ program: route.params.program, propsDogId: route.query.dogId }),
        meta: {
            layout: 'no-sidebar', name: MetaNames.AdminTitlingReview }
    },
    {
        path: '/admin/titling/review',
        name: 'titlingreview',
        component: TitlingReview,
        meta: {
            layout: 'no-sidebar', name: MetaNames.AdminTitlingReview}
    },
    {
        path: '/admin/titling/process',
        name: 'titlingprocess',
        component: TitlingProcess,
        meta: {
            layout: 'no-sidebar', name: MetaNames.AdminCertificateGeneration }
    },
    {
        path: '/admin/registration/qtdogs',
        name: 'QTDogRegistrationList',
        component: QTDogRegistrationList,
        meta: {
            layout: 'left-sidebar', name: MetaNames.AdminQTDOGRegistrations }
    },
    {
        path: '/admin/registration/qtdogs/:requestId',
        name: 'QTDogRequestCard',
        component: QTDogRequestCard,
        props: route => ({ requestId: Number(route.params.requestId) }),
    },
    {
        path: '/admin/registration/kennels',
        name: 'KennelRegistrationList',
        component: KennelRegistrationList,
        meta: {
            layout: 'left-sidebar', name: MetaNames.AdminKennelRegistrations }
    },
    {
        path: '/admin/registration/kennels/:requestId',
        name: 'KennelRegistrationCard',
        component: KennelRegistrationCard,
        props: route => ({ requestId: Number(route.params.requestId) }),
    },
    {
        path: '/admin/registration/litters',
        name: 'LitterRequestList',
        component: LitterRequestList,
        meta: {
            layout: 'left-sidebar', name: MetaNames.AdminLitterRegistrations }
    },
    {
        path: '/admin/registration/leases/new',
        name: 'AdminRegisterLease',
        component: RegisterLease,
        props: true,
        meta: { layout: 'left-sidebar', name: MetaNames.AdminLeaseRegistrations }
    },
    {
        path: '/admin/search/litters',
        name: 'LitterList',
        component: LitterList,
        meta: { layout: 'left-sidebar', name: MetaNames.AdminLitters }
    },
    {
        path: '/admin/search/dogs',
        name: 'DogList',
        component: DogList,
        props: true,
        meta: {
            layout: 'left-sidebar', name: MetaNames.AdminDogs }
    },
    {
        path: '/admin/search/shows',
        name: 'ShowList',
        component: ShowList,
        props: true,
        meta: {
            layout: 'left-sidebar', name: MetaNames.AdminShows }
    },
      {
        path: '/admin/shows/:showId?',
        name: 'ShowDetails',
        component: ShowDetails,
        props: route => ({ showId: route.params.showId }),
          meta: {
              layout: 'left-sidebar', name: MetaNames.AdminShowDetails}
      },
      {
        path: '/admin/litters/:litterId?',
        name: 'LitterDetails',
        component: LitterDetails,
        props: route => ({ litterId: convertToNullableNumber(route.params.litterId) }),
          meta: {
              layout: 'left-sidebar', name: MetaNames.AdminLitterDetails}
      },
      {
        path: '/admin/search/leases',
        name: 'LeaseList',
        component: LeaseList,
          meta: {
              layout: 'left-sidebar', name: MetaNames.AdminLeases}
      },
      {
        path: '/admin/search/kennels',
        name: 'KennelList',
        component: KennelList,
        props: true,
          meta: {
              layout: 'left-sidebar', name: MetaNames.AdminKennels}
    },
    {
        path: '/admin/kennels/:kennelId?',
        name: 'KennelDetails',
        component: KennelDetails,
        props: true,
        meta: {
            layout: 'left-sidebar', name: MetaNames.AdminKennelDetails }
    },
    {
        path: '/admin/leases/:leaseId?',
        name: 'LeaseDetails',
        component: LeaseDetails,
        props: route => ({ leaseId: Number(route.params.leaseId) }),
        meta: {
            layout: 'left-sidebar', name: MetaNames.AdminLeaseDetails }
    },
    
    {
        path: '/admin/registration/leases',
        name: 'LeaseRequestList',
        component: LeaseRequestList,
        meta: {
            layout: 'left-sidebar', name: MetaNames.AdminLeaseRegistrations }
    },
    {
        path: '/admin/registration/lease/:requestId',
        name: 'LeaseRequestCard',
        component: LeaseRequestCard,
        props: route => ({ requestId: Number(route.params.requestId) }),
    },
    {
        path: '/admin/registration/litters/:requestId',
        name: 'LitterRequestCard',
        component: LitterRequestCard,
        props: route => ({ requestId: Number(route.params.requestId) }),
    },
    {
        path: '/admin/changekennelowners/:requestId',
        name: 'ChangeKennelOwnerCard',
        component: ChangeKennelOwnerCard,
        props: route => ({ requestId: Number(route.params.requestId) }),
        meta: {
            layout: 'left-sidebar', name: MetaNames.AdminKennelChangeOwners }
    },
    {
        path: '/admin/changekennelowners',
        name: 'ChangeKennelOwnerList',
        component: ChangeKennelOwnerList,
        meta: { layout: 'left-sidebar', name: MetaNames.AdminKennelChangeOwners }
    },
    {
        path: '/admin/registration/affiliate',
        name: 'CreateAffiliateList',
        component: CreateAffiliateList,
        meta: { layout: 'left-sidebar', name: MetaNames.AdminAffiliateRegistrations }
    },
    {
        path: '/admin/registration/affiliate/:requestId',
        name: 'CreateAffiliateCard',
        component: CreateAffiliateCard,
        props: route => ({ requestId: Number(route.params.requestId) }),
    },
    {
        path: '/admin/renew/affiliate',
        name: 'RenewAffiliateList',
        component: RenewAffiliateList,
        meta: { layout: 'left-sidebar', name: MetaNames.AdminAffiliateRenewals }
    },
    {
        path: '/admin/renew/affiliate/:requestId',
        name: 'RenewAffiliateCard',
        component: RenewAffiliateCard,
        props: route => ({ requestId: Number(route.params.requestId) }),
    },
     {
        path: '/admin/search/people',
        name: 'PeopleList',
        component: PeopleList,
         meta: {
             layout: 'left-sidebar', name: MetaNames.AdminPeople }
     },
    {
        path: '/admin/people/:personId?',
        name: 'PersonDetails',
        component: PersonDetails,
        props: route => ({ personId: Number(route.params.personId) }),
        meta: {
            layout: 'left-sidebar', name: MetaNames.AdminPersonDetails }
    },
    {
        path: '/admin/search/affiliates',
        name: 'AdminAffiliateList',
        component: AdminAffiliateList,
        meta: {
            layout: 'left-sidebar', name: MetaNames.AdminAffiliates }
    },
    {
        path: '/admin/dogs/:dogId?',
        name: 'DogDetails',
        component: DogDetails,
        props: route => ({ dogId: decodeURIComponentIfNotEmpty(route.params.dogId) }),
        meta: {
            layout: 'left-sidebar', name: MetaNames.AdminDogDetails }
    },
    {
        path: '/admin/affiliates/:affiliateId?',
        name: 'AffiliateDetails',
        component: AffiliateDetails,
        props: true,
        meta: {
            layout: 'left-sidebar', name: MetaNames.AdminAffiliateDetails }
    },
    {
        path: '/admin/search/puppyrelease',
        name: 'PuppyReleaseList',
        component: PuppyReleaseList,
        meta: {
            layout: 'left-sidebar', name: MetaNames.AdminPuppyReleases }
    },
    {
        path: '/admin/categorymanagement',
        name: 'CategoryManagement',
        component: CategoryManagement,
        meta: {
            layout: 'left-sidebar', name: MetaNames.AdminCategoryManagement }
    },
    {
        path: '/admin/certificates',
        name: 'CertPrinting',
        component: CertPrinting,
        meta: {
            layout: 'left-sidebar', name: MetaNames.AdminCertificatePrinting }
    },
    {
        path: '/admin/members-only',
        name: 'MembersOnlyEdit',
        component: MembersOnlyEdit,
        meta: { layout: 'left-sidebar', name: MetaNames.AdminMembersOnlyEdit }
    },
    {
        path: '/admin/reports/membership',
        name: 'MembershipReport',
        component: MembershipReport,
        meta: { layout: 'left-sidebar', name: MetaNames.MembershipReport }
    },
]

export default routes.map(route => {
    return { ...route, meta: { ...route.meta, isPublic: false, isAdmin: true } };
});